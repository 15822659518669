import React, { Component, lazy, Suspense } from 'react'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE } from '../constants.js'

import Header from './Header'
const Footer = lazy(() => import ('./Footer'))

import './App.css'
import './LandingPage.css'
import './SpinPage.css'


const AboutPage = lazy(() => import ('./AboutPage'))
const AccountPage = lazy(() => import ('./AccountPage'))
const ConfirmationPage = lazy(() => import ('./ConfirmationPage'))
const ConfirmPage = lazy(() => import ('./ConfirmPage'))
const ContactPage = lazy(() => import ('./ContactPage'))
const CurrentShowsPage = lazy(() => import ('./CurrentShowsPage'))
const FaqPage = lazy(() => import ('./FaqPage'))
const HowPage = lazy(() => import ('./HowPage'))
const JobsPage = lazy(() => import ('./JobsPage'))
const LandingPage = lazy(() => import ('./LandingPage'))
const NotFoundPage = lazy(() => import ('./NotFoundPage'))
const PressPage = lazy(() => import ('./PressPage'))
const PrivacyPage = lazy(() => import ('./PrivacyPage'))
const RatingPage = lazy(() => import ('./RatingPage'))
const ShowPage = lazy(() => import ('./ShowPage'))
const SpinPage = lazy(() => import ('./SpinPage'))
const TermsPage = lazy(() => import ('./TermsPage'))
const IERedirect = lazy(() => import ('./IERedirect'))

const reload = () => window.location.reload()

const isIE = /*@cc_on!@*/false || !!document.documentMode
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d11f34'
    },
    secondary: {
      main: '#0e0d0d',
    },
  },
})

class App extends Component {

  state = {}

  _closeCookiesBanner() {
    this.setState({ showCookiesBanner: false })
    window.sessionStorage.setItem( 'DisplayCookiesBanner', false )
  }

  _logout() {
    window.localStorage.removeItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE)
    window.location.reload()
  }

  _startGAUpdates() {
    this.props.history.listen((location, action) => {
      if (window.ga) {
        window.ga('send', 'pageview', location.pathname)
      }
    })
  }

  componentDidUpdate() {
    this._startGAUpdates()
  }

  componentDidMount() {
    var showCookiesBanner = sessionStorage.getItem( 'DisplayCookiesBanner' ) ?
                            sessionStorage.getItem( 'DisplayCookiesBanner' ) : true
    showCookiesBanner = showCookiesBanner !== 'false'

    this.setState({ showCookiesBanner })
  }

  render() {
    var user = this.props.data.user

    if (isIE && this.props.history.location.pathname !== '/IERedirect'){
       this.props.history.push('/IERedirect')
    }

    return (
      <div>
        <Helmet>
          <title>Theatre Roulette</title>
          <meta name="description" content="The fun and easy way to see a West End show for just £19!" />
        </Helmet>
        <MuiThemeProvider theme={theme}>
          <div>
            <Header {...this.props} user={this.props.data.user} />

            <div style={{minHeight: '50vh'}}>
              <Suspense fallback={<div></div>}>
                <div>
                  <Switch onChange={window.scrollTo(0, 0)}>

                    {/* Dynamic Pages  */}
                    <Route exact path='/account' render={(props) => <AccountPage {...props} theme={theme} /> }/>
                    <Route exact path='/buy-tickets' render={(props) => <SpinPage {...props} isSafari={isSafari} theme={theme} user={user} />} />
                    <Route exact path='/confirm/:orderId' render={(props) => <ConfirmPage {...props}/> }/>
                    <Route exact path='/confirmation/:orderId' render={(props) => <ConfirmationPage {...props}/> }/>
                    <Route exact path='/current-shows' render={(props) => <CurrentShowsPage {...props}/> }/>
                    <Route exact path='/' render={(props) => <LandingPage {...props}/> }/>
                    <Route exact path='/rate/:orderId' render={(props) => <RatingPage {...props}/> }/>
                    <Route exact path='/show/:showSlug' render={(props) => <ShowPage {...props} isSafari={isSafari} /> }/>

                    {/* Static Pages */}
                    <Route exact path='/about' render={() => <AboutPage/> }/>
                    <Route exact path='/contact' render={() => <ContactPage/> }/>
                    <Route exact path='/faq' render={() => <FaqPage/> }/>
                    <Route exact path='/how-it-works' render={() => <HowPage isSafari={isSafari} /> }/>
                    <Route exact path='/IERedirect' render={() => <IERedirect/> }/>
                    <Route exact path='/careers' render={() => <JobsPage/> }/>
                    <Route exact path='/press' render={() => <PressPage/> }/>
                    <Route exact path='/privacy' render={() => <PrivacyPage/> }/>
                    <Route exact path='/terms' render={() => <TermsPage/> }/>

                    {/* Constants */}
                    <Route exact path='/sitemap.xml' onEnter={reload}/>
                    <Route path='*' render={() => <NotFoundPage/> }/>

                  </Switch>

                  {this.state.showCookiesBanner &&
                    <div className="bar">
                      <div>By continuing to browse the site, you are agreeing to our use of cookies. Visit
                      our <a className="privacy-link" href="/privacy">Privacy Policy</a> to learn more</div>
                      <div className="cookies-button" onClick={() => { this._closeCookiesBanner() }}>Got it!</div>
                    </div>
                  }

                  <Footer/>
                </div>
              </Suspense>
            </div>

          </div>
        </MuiThemeProvider>
      </div>
    )
  }
}

const USER_QUERY = gql`
  query {
    user {
      id
      email
      firstName
      lastName
    }
  }
`

export default graphql(USER_QUERY, {
  options: { fetchPolicy: 'network-only' }
})(withRouter(App))
