export const GA_EVENT = {
  INTERACTION: 'interaction',
  VIEW: 'view',
  EVENT: 'event'
}

export const GA_ACTION = {
  INTERACTION: {
    DATE_PICKER: 'clicked get date picker',
    NUMBER_OF_TICKETS: 'clicked on number of tickets field',
    FIRST_NAME: 'clicked on first name field',
    LAST_NAME: 'clicked on last name field',
    EMAIL: 'clicked on email field',
    PHONE: 'clicked on phone field',
    SHOW_PREFERENCE: 'clicked on show preference',
    SHOW_TIME: 'clicked on show time',

    EXCLUDED_SHOW: 'clicked on show to exclude it',
    UNEXCLUDED_SHOW: 'clicked on show to unexclude it',

    DISCOUNT: 'clicked on discount field',

    CREDIT_CARD: 'clicked on credit card number field',
    EXPIRATION_MONTH: 'clicked on expiration month field',
    EXPIRATION_YEAR: 'clicked on expiration year field',
    CVC_CODE: 'clicked on cvc field',

    SUBMIT_PURCHASE: 'clicked to submit purchase',
    SUBMIT_PURCHASE_ACCOUNT_RESTAURANT_RESERVATION: 'clicked to submit purchase',
    SUBMIT_PURCHASE_GIFT_CARD: 'clicked to submit gift card purchase',

    FAMILY_ROULETTE: 'clicked family roulette',
    PREMIUM_ROULETTE: 'clicked premium roulette',
    REGULAR_ROULETTE: 'clicked regular roulette',

    EXTRA_EXCLUSION: 'clicked to toggle extra exclusion',
    NO_EXTRA_EXCLUSION: 'clicked no on extra exclusion',
    YES_EXTRA_EXCLUSION: 'clicked yes on extra exclusion',
    EXTENDED_HOURS: 'clicked to toggle extended hours',
    CANCEL_EXTENDED_HOURS: 'clicked cancel extended hours',
    NO_SPECIAL_SHOWS: 'clicked no on special shows',
    YES_SPECIAL_SHOWS: 'clicked yes on special shows',
    CLOSE_POPUP: 'clicked to close popup',

    RESTAURANT: 'clicked restaurant checkbox',
    RESTAURANT_YES_POPUP: 'clicked yes on restaurant popup',
    TICKETFLEX: 'clicked ticketflex checkbox',
    TICKETFLEX_YES_POPUP: 'clicked yes on ticketflex popup',

    ACCOUNT_RESTAURANT_RESERVATION_SUBMIT: 'clicked to add restaurant reservation on account page',

    SUBMIT_REFERRAL: 'entered email for referral',

    HOMEPAGE_VIDEO: 'clicked to view video on homepage',

    BUY_TICKETS_HEADER: 'clicked buy tickets in header',
    BUY_TICKETS_FOOTER: 'clicked buy tickets in footer',
    BUY_TICKETS_BUTTON: 'clicked buy tickets button',
    BUY_TICKETS_FAQ: 'clicked buy tickets button on FAQ page',
    BUY_TICKETS_ABOUT: 'clicked buy tickets button on about page',
    BUY_TICKETS_CURRENT_SHOWS: 'clicked buy tickets button on current shows page',
    BUY_TICKETS_SHOW: 'clicked buy tickets button on show page',
    BUY_TICKETS_GIFT: 'clicked buy tickets button on gift card page',
    BUY_TICKETS_HOW: 'clicked buy tickets button on how it works page',
    BUY_TICKETS_PRESS: 'clicked buy tickets button on press page',

    MONTHLY_SPIN: 'submitted email on monthly spin',
    FOOTER_SUBMIT: 'submitted email on footer input',
    SPIN_MAILING_SUBMIT: 'submitted email on sold out spin page',
    SUBSCRIBE_AND_SAVE_HEADER: 'clicked subscribe and save in header',

    FOOTER_PRESS_CLICK: 'clicked a footer press link',
    TODAYTIX_FAQ_LINK: 'clicked on TodayTix link in FAQ'
  },
  VIEW: {
    EXCLUSION_POPUP: 'viewed extra exclusion popup',
    EXTENDED_HOURS_POPUP: 'viewed extended hours popup',
    RESTAURANT_POPUP: 'viewed restaurant popup',
    TICKETFLEX_POPUP: 'viewed ticketflex popup',
  },
  EVENT: {
    GIFT_CARD_ORDER_INVALID: 'evented on invalid order after submitting gift card purchase',
    PAYMENT_FAILED: 'evented on failed payment after submitting purchase',
    PAYMENT_SUCCEEDED: 'evented on successful payment after submitting purchase',
    PAYMENT_ACCOUNT_RESTAURANT_RESERVATION_FAILED: 'evented on successful payment after adding restaurant reservation',
    PAYMENT_ACCOUNT_RESTAURANT_RESERVATION_SUCCEEDED: 'evented on successful payment after adding restaurant reservation',
    PAYMENT_GIFT_CARD_FAILED: 'evented on successful payment after gift card',
    PAYMENT_GIFT_CARD_SUCCEEDED: 'evented on successful payment after gift card',
    ORDER_INVALID: 'evented on invalid order after submitting purchase',
    LOGIN_FAILURE: 'evented on login failure',
    RESTAURANT_RESERVATION_ADD_ON_ORDER_INVALID: 'evented on invalid order after submitting restaurant reservation add on purchase',
  }
}

export const sendGAEvent = (event, action, options) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    console.log(action)
    window.ga('send', 'event', event, action)
    gtag('event', action)
  } else (
    console.log('would fire event: ' + event + ', action: ' + action)
  )
}

export const sendGAEcommerceEventsSpinPage = (orderId, numberOfTickets, pricing, requestedShowDate) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    window.ga('ecommerce:clear')
    window.ga('ecommerce:addTransaction', {'id': orderId,'revenue': pricing.totalCost.toFixed(2)})
    var gtmProducts = []

    window.ga('ecommerce:addItem', {'id': orderId,
                                    'name': 'Tickets',
                                    'sku': 'tickets',
                                    'quantity': numberOfTickets,
                                    'price': (pricing.ticketCostOnDate + pricing.perTicketFee).toFixed(2)
                                   })
    gtmProducts.push({
      'item_name': 'Ticket',
      'item_id': 'ticket',
      'price': (pricing.ticketCostOnDate + pricing.perTicketFee).toFixed(2),
      'quantity': numberOfTickets,
      'item_variant': requestedShowDate
    })

    if (pricing.allExtraEliminationsFeePerTicket) {
      window.ga('send', 'event', 'purchased', 'additional exclusions')
      window.ga('ecommerce:addItem', {'id': orderId + '-additionalExclusions',
                                      'name': 'Additional Exclusions',
                                      'sku': 'additional-exclusions',
                                      'quantity': numberOfTickets,
                                      'price': pricing.allExtraEliminationsFeePerTicket.toFixed(2)
                                     })

      gtmProducts.push({
        'item_name': 'Additional Exclusions Add-On',
        'item_id': 'additional-exclusions',
        'price': pricing.allExtraEliminationsFeePerTicket.toFixed(2),
        'quantity': numberOfTickets,
        'item_variant': requestedShowDate
      })
    }

    if (pricing.familyRouletteFeePerTicket) {
      window.ga('send', 'event', 'purchased', 'family friendly')
      window.ga('ecommerce:addItem', {'id': orderId + '-family',
                                      'name': 'Family Friendly',
                                      'sku': 'family-friendly',
                                      'quantity': numberOfTickets,
                                      'price': (pricing.familyRouletteFeePerTicket).toFixed(2)
                                     })

      gtmProducts.push({
        'item_name': 'Family Friendly Add-On',
        'item_id': 'family-friendly',
        'price': (pricing.familyRouletteFeePerTicket).toFixed(2),
        'quantity': numberOfTickets,
        'item_variant': requestedShowDate
      })
    }

    if (pricing.premiumFeePerTicket) {
      window.ga('send', 'event', 'purchased', 'premium')
      window.ga('ecommerce:addItem', {'id': orderId + '-premium',
                                      'name': 'Premium',
                                      'sku': 'premium',
                                      'quantity': numberOfTickets,
                                      'price': pricing.premiumFeePerTicket.toFixed(2)
                                     })

      gtmProducts.push({
        'item_name': 'Premium Add-On',
        'item_id': 'premium',
        'price': pricing.premiumFeePerTicket.toFixed(2),
        'quantity': numberOfTickets,
        'item_variant': requestedShowDate
      })
    }

    if (pricing.restaurantFee) {
      window.ga('send', 'event', 'purchased', 'restaurant reservation')
      window.ga('ecommerce:addItem', {'id': orderId + '-restaurant',
                                      'name': 'RestaurantReservation',
                                      'sku':'restaurant-reservation',
                                      'quantity': 1,
                                      'price': pricing.restaurantFee.toFixed(2)
                                     })

      gtmProducts.push({
        'item_name': 'Restaurant Reservation',
        'item_id': 'restaurant-reservation',
        'price': pricing.restaurantFee.toFixed(2),
        'quantity': 1,
        'item_variant': requestedShowDate
      })
    }

    if (pricing.ticketFlexFeePerTicket) {
      window.ga('send', 'event', 'purchased', 'ticketflex')
      window.ga('ecommerce:addItem', {'id': orderId + '-ticketflex',
                                      'name': 'TicketFlex',
                                      'sku': 'ticket-flex',
                                      'quantity': numberOfTickets,
                                      'price': (pricing.ticketFlexFeePerTicket).toFixed(2)
                                     })

      gtmProducts.push({
        'item_name': 'TicketFlex Add-On',
        'item_id': 'ticket-flex',
        'price': (pricing.ticketFlexFeePerTicket).toFixed(2),
        'quantity': numberOfTickets,
        'item_variant': requestedShowDate
      })
    }

    window.ga('ecommerce:send')

    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'items': gtmProducts
      }
    })
  } else (
    console.log('would fire ga buy tickets ecomm event')
  )
}

export const sendGAEcommerceEventsGiftCard = (orderId, totalCost) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    window.ga('ecommerce:clear')
    window.ga('ecommerce:addTransaction', {'id': orderId,'revenue': totalCost})
    window.ga('ecommerce:addItem', {'id': orderId, 'name': 'Gift Card', 'sku':'gift-card', 'quantity': 1, 'price': totalCost})
    window.ga('ecommerce:send')

    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'items': [{
          'item_name': 'Gift Card',
          'item_id': 'gift-card',
          'price': totalCost,
          'quantity': 1
        }]
      }
    })

  } else (
    console.log('would fire ga gift card ecomm event')
  )
}

export const sendGAEcommerceEventsRestaurantAccount = (orderId, restaurantReservationFee) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    window.ga('ecommerce:clear')
    window.ga('ecommerce:addTransaction', {'id': orderId,'revenue': restaurantReservationFee.toFixed(2)})
    window.ga('ecommerce:addItem', {'id': orderId,
                                    'name': 'Restaurant Reservation',
                                    'sku': 'restaurant-reservation',
                                    'quantity': 1,
                                    'price': restaurantReservationFee.toFixed(2)
                                   })
    window.ga('ecommerce:send')

    window.dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'items': [{
          'item_name': 'Restaurant Reservation',
          'item_id': 'restaurant-reservation',
          'price': restaurantReservationFee.toFixed(2),
          'quantity': 1
        }]
      }
    })

  } else (
    console.log('would fire ga restaurant ecomm event')
  )
}

/* GA4 */
export const sendPurchaseGAEvent = (orderId, orderTotal, price, itemID, itemName, quantity, promoCode, discount) => {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    console.log('purchase')

    gtag('event', 'purchase', {
      transaction_id: orderId,
      value: orderTotal,
      currency: 'GBP',
      coupon: promoCode,
      items: [
       {
        item_id: itemID,
        item_name: itemName,
        affiliation: 'Theatre Roulette',
        coupon: promoCode,
        discount: discount,
        location_id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI',
        price: price,
        quantity: quantity
      }]
    })

  } else (
    console.log(`would fire event: purchased, ${orderId}, ${orderTotal}, ${promoCode}`)
  )
}