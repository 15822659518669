const moment = require('moment-timezone')

export const ENV = process.env.REACT_APP_STAGE

// Clients
const ENV_TO_BR_API = {
  'DEV': 'http://localhost:4000',
  'PROD': 'https://beta-api.broadwayroulette.com',
}

const ENV_TO_PAYPAL_ACCESS_KEY = {
  'DEV': process.env.REACT_APP_PAYPAL_DEV_KEY,
  'PROD': process.env.REACT_APP_PAYPAL_LIVE_KEY,
}

const ENV_TO_STRIPE_ACCESS_KEY = {
  'DEV': process.env.REACT_APP_STRIPE_DEV_KEY,
  'PROD': process.env.REACT_APP_STRIPE_LIVE_KEY,
}

export const MailChimp = {
  OptInUrl: {
    MonthlySpin: 'https://broadwayroulette.us12.list-manage.com/subscribe?u=3c4c9afdbb28160af716b104d&id=d095aca221',
    AllCustomers: 'http://broadwayroulette.us12.list-manage.com/subscribe?u=3c4c9afdbb28160af716b104d&id=2b10bec198',
  },
}

export const PAYPAL_ACCESS_KEY = ENV_TO_PAYPAL_ACCESS_KEY[ENV]
export const STRIPE_ACCESS_KEY = ENV_TO_STRIPE_ACCESS_KEY[ENV]
export const BR_API_URL = ENV_TO_BR_API[ENV]
export const BR_API_KEY = process.env.REACT_APP_BR_API_KEY

export const GC_USER_ID = 'graphcool-user-id'
export const GC_AUTH_TOKEN = 'graphcool-auth-token'
export const GRAPHQL_API_URL = 'https://api.8base.com/ck5o3an76000508ladchh9i4h'
export const GRAPHQL_AUTH_CLIENT_ID = process.env.REACT_APP_GRAPHQL_AUTH_CLIENT_ID
export const GRAPHQL_AUTH_DOMAIN = 'auth.broadwayroulette.com'
export const GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE = 'br-auth-token'
export const GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION = 'br-auth-token-expiration'

export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY
export const AWS_REGION = 'us-west-2'

// Business Logic
export const SHOW_RATE_LIMIT_DEFAULT = 4
export const RATE_LIMIT_PERIOD_DEFAULT = 28

export const ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER = 120

export const MAX_ELIMINATION_COUNT = 4
export const UPCHARGE_ADDITIONAL_EXCLUSIONS = 2

export const MIN_TICKET_REQUEST = 1
export const MAX_TICKET_REQUEST = 9

export const EXCLUDING_TOO_MUCH_COPY = 'Note: Because there are 3 or fewer shows that match your preferred show type (musical or play), we may include shows of the other type in your spin.'

// General Logic
export const USD_DOLLARS_TO_CENTS = 100
export const LOCALE = 'Europe/London'
export const TODAY = moment().tz(LOCALE).format('YYYY-MM-DD')
export const TOMORROW = moment().tz(LOCALE).add(1, 'days').format('YYYY-MM-DD')
export const TODAY_DATE_TIME = moment().tz(LOCALE).format('YYYY-MM-DD HH:mm:ss')
export const TODAY_DAY = moment().tz(LOCALE).format('dddd')

export const HOLIDAY_DATES = ['2023-12-15', '2023-12-16', '2023-12-17', '2023-12-18', '2023-12-19', '2023-12-20',
                              '2023-12-21', '2023-12-22', '2023-12-23', '2023-12-24', '2023-12-25', '2023-12-26',
                              '2023-12-27', '2023-12-28', '2023-12-29', '2023-12-30', '2023-12-31', '2024-01-01']

export const MINI_HOLIDAY_DATES = ['2023-07-13', '2023-07-14']
