import React, { Component } from 'react'
import Auth0Lock from 'auth0-lock'
import { withRouter } from 'react-router-dom'

import { GA_ACTION, GA_EVENT } from '../analytics'
import { GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE, GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION } from '../constants'

import './LoginAuth0.css'

class LoginAuth0 extends Component {
  constructor (props) {
    super(props)

    var baseURL = `${window.location.protocol}//${window.location.host}`
    var redirectUrl = `${baseURL}/account`

    var options = {
      configurationBaseUrl: 'https://cdn.auth0.com',
      auth: {
        redirectUrl: redirectUrl,
        responseType: 'token id_token',
        params: {
          scope: 'openid profile email'
        }
      },
      theme: {
        primaryColor: "black"
      },
      languageDictionary: {
        emailInputPlaceholder: "Email",
        passwordInputPlaceholder: 'Password',
        forgotPasswordAction: "Forgot your password?",
      },
    }

    this._lock = new Auth0Lock(props.clientId, props.domain, options)
  }

  _showLogin = () => {
    this._lock.show()
  }

  render() {
    return (
      <span onClick={this._showLogin}>
        {this.props.children}
      </span>
    )
  }

  componentDidMount() {
    this._lock.on('authenticated', (authResult) => {
      this._lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) { /* TODO: Handle error */ return; }

        window.localStorage.setItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE, authResult.idToken)
        window.localStorage.setItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE_EXPIRATION, authResult.idTokenPayload.exp)
        this.props.history.push('/account')
        if (window.location.pathname === '/account') {
          window.location.reload()
        }
      })
    })
    this._lock.on('authorization_error', (error) => {
      window.ga('send', 'event', GA_EVENT.EVENT, GA_ACTION.EVENT.LOGIN_FAILURE, error.errorDescription)
      this._lock.show({
        flashMessage: {
          type: 'error',
          text: "Please make sure you have cookies enabled/ad blockers off and try again."
        }
      })
    })
  }
}

export default withRouter(LoginAuth0)
